import { RcFile } from "antd/es/upload";

export { useApi } from "Services/AbstractApiService";
import { apiHeaders, configureServer } from "Services/AbstractApiService";
import { getApiUrl } from "Services/ProxyConfig";
import { fetch } from "Services/util/NetworkUtilService";
import { MessagePreview } from "Types/Campaign";
import { SegmentCondition, FieldLookup, JoinType } from "Types/Segment";

export const makeRequest = configureServer({ host: getApiUrl() });

export async function getGoogleSsoRequired(email: string): Promise<{ required: boolean; redirect: string }> {
    const headers = apiHeaders();
    const result = await makeRequest<{ required: boolean; redirect?: string }>({
        headers,
        endpoint: `/authenticate/google-sso-required?email=${encodeURIComponent(email)}&validateDomain=true`,
        errors: {
            notFound: "Route not found",
            other: "Error fetching results",
        },
    });

    return {
        required: result.required,
        redirect: result.redirect || "either",
    };
}

export function triggerCampaignTest(
    body: MessagePreview,
    type: "SMS" | "Phone Call"
): Promise<{ result: string; error: string }> {
    return makeRequest<any>({
        endpoint: `/interactions`,
        errors: {
            notFound: "Contact not found",
            other: `Error triggering preview ${type}`,
        },
        body,
    });
}

export function getRootProperties(): Promise<FieldLookup[]> {
    return makeRequest<FieldLookup[]>({
        endpoint: `/segments/rootProperties`,
        errors: {
            notFound: "Route not found",
            other: "Error triggering the sms",
        },
    });
}

export function getTypeAheadValues(body: { token: string }): Promise<FieldLookup[]> {
    return makeRequest<FieldLookup[]>({
        endpoint: `/segments/nestedProperties`,
        errors: {
            notFound: "Route not found",
            other: "Error fetching nested properties",
        },
        body,
    });
}

export function refreshSegmentCounter(body: {
    conditions: SegmentCondition[];
    joinType: JoinType;
}): Promise<{ count: number }> {
    return makeRequest<{ count: number }>({
        endpoint: `/segments/countFromConditions`,
        errors: {
            notFound: "Route not found",
            other: "Error refreshing results",
        },
        body,
    });
}

export function uploadSegmentCSV(
    file: RcFile,
    segment_uuid: string,
    includeCsvDataTypeRow: boolean
): Promise<{ path: string; id: string; url: string }> {
    const formData = new FormData();
    formData.set("includeCsvDataTypeRow", includeCsvDataTypeRow?.toString() || "false");
    formData.append("file", file);
    const params = {
        method: "POST",
        headers: apiHeaders(),
        body: formData,
    };
    return fetch(`${getApiUrl()}/segments/${segment_uuid}/uploadFile`, params).then((response: Response) => {
        if (response.status == 404) {
            throw "Route not found";
        } else if (response.status === 400) {
            return response.json().then((e) => {
                throw e;
            });
        } else if (!response.ok) {
            throw "Error uploading file";
        }
        return response.json();
    });
}

export function authenticateHubspot(code: string): Promise<any> {
    return makeRequest({
        endpoint: `/hubspot/oauth-callback/${code}`,
        errors: {
            notFound: "Route not found",
            other: "Error fetching results",
        },
    });
}

export function connectCalendly(calendlyKey: string): Promise<any> {
    return makeRequest({
        endpoint: "/calendly/connect",
        body: {
            calendlyKey,
        },
        errors: {
            notFound: "Calendly Connect route not found",
            other: "Error configuring calendly integration while connecting",
        },
    });
}

export function disconnectCalendly(): Promise<any> {
    return makeRequest({
        endpoint: "/calendly/disconnect",
        method: "POST",
        errors: {
            notFound: "Calendly Disconnect route not found",
            other: "Error configuring calendly integration while disconnecting",
        },
    });
}

export function authorizeSalesforce(code: string, redirectUri: string, authorizationHost: string): Promise<any> {
    return makeRequest({
        endpoint: `/salesforce/authorize/`,
        body: {
            code,
            redirectUri,
            authorizationHost,
        },
        method: "POST",
        errors: {
            notFound: "Route not found",
            other: "Error fetching results",
        },
    });
}

export function disconnectSalesforce(): Promise<any> {
    return makeRequest({
        endpoint: `/salesforce/disconnect/`,
        method: "POST",
        errors: {
            notFound: "Route not found",
            other: "Error with Salesforce integration while disconnecting",
        },
    });
}
